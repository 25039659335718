import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../components/seo";
import Layout from "../components/layouts/layout";
import FormularioBasico from "../components/widgets/formularios/FormularioBasico";
import Image from "../components/widgets/basico/image";

export default function TallerColision(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem="taller-colision" parameters={parameters}>
            <SEO
                title="Rivero Nissan - Taller Nissan - Autos Usados Seminuevos Venta Renta"
                description="Grupo Rivero - Taller Nissan - Taller de Colisión - Servicio Nissan - Agencia Nissan Monterrey - Seminuevos - March, Versa, Sentra, Kicks, Xtrail, Altima"
                keywords="Taller Nissan, taller de servicio automotriz, seguros nissan, servicio nissan"
                path="/taller-de-colision"
            />
            <Container>
                <div className="bg-white">
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            {<Image fileName="banner-nissan-rivero-centro-colision.jpg" alt="Taller de colision"/>}
                            <h1 className="p-4">
                                El mejor taller Nissan.
                            </h1>
                            <div className="p-4">
                                {" "}
                                <p>
                                En el Rivero Nissan entendemos que tu vehículo es una parte esencial de tu vida diaria, por lo que nos esforzamos al máximo para ofrecerte un servicio rápido, eficiente y de calidad
                                </p>
                                <p>
                                Nuestro equipo se encarga de reparar cualquier tipo de problema que pueda haber ocurrido en tu vehículo. Desde pequeños rasguños hasta daños más graves, estos expertos están preparados para solucionar cualquier situación.
                                </p>
                                <p>
                                Puedes estar tranquilo primo ya que en Nissan Rivero trabajamos día con día para que la estadía de tu auto sea solamente la necesaria.
                                </p>
                                {/* <p style={{ fontSize: "22px" }}>
                                Aseguradoras con las que trabajamos
                                </p>
                                <p>
                                Además en <strong>Grupo Rivero</strong> tenemos convenios con
                                las principales y más prestigiosas compañías{" "}
                                <strong>aseguradoras en México,</strong> como lo son{" "}
                                <strong>
                                    Aba Seguros, Seguros Banorte Generali, Axa Seguros, Mapfre,
                                    Seguros GNP, Qualitas, Inbursa y Zurich.
                                </strong>
                                </p>
                                <p>
                                Gracias a estos convenios, la cobertura de tu auto se extiende
                                por toda la república mexicana, permitiendo que viajes seguro
                                a todas partes con la confianza de que Grupo Rivero, siempre
                                está listo para respaldarte en caso de algún siniestro o
                                problema
                                </p>
                                <p>
                                Consulta los planes para conocer el seguro ideal para tu
                                presupuesto y el de tu familia.
                                </p>
                                <br />
                                <Image fileName="logo_seguros.png" alt="Seguros"/> */}
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="p-4">
                            <h2>Contáctanos</h2>
                            <FormularioBasico pagina="taller-de-colision" btnTitulo="Contactar" props={props} subject={'Servicio Colisión'}>
                                <input
                                    type="hidden"
                                    name="subject"
                                    id="subject"
                                    value="Taller de Colisión"
                                    hidden
                                />
                            </FormularioBasico>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    )

}